.App {
  text-align: center;
}

h2 {
  line-height: 33.6px;
  text-align: center;
  margin: 10px 0px;
}

.textarea-querytext {
  width: 90%;
  border-radius: 8px;
  padding: 10px;
  height: 200px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
  height: 100vh;
  width: 100%;
  background-color: #B6BF0D;
  color: white;
  font-size: 1.5em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loader {
  display: flex;
  align-items: center;
}

.button-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 16px;
  margin: 10px 0px;
}

.main {
  padding: 0px !important;
  height: 100vh;
}

.questionnaire {
  position: absolute;
  background-color: #FFF;
  border-radius: 20px 20px 0px 0px;
  padding: 20px;
  height: 70vh;
  bottom: 0px;
  margin: 0px
}

.textarea-container {
  position: relative;
}

.textarea-container textarea {
  width: 100%;
  transition: height 0.3s ease;
}

.textarea-container textarea.expanded {
  height: 150px; /* Increase height when suggestions are shown */
}

.textarea-container .suggestions-list {
  position: absolute;
  top: 100%; /* Places the dropdown exactly below the input */
  left: 10px;
  width: calc(100% - 20px);
  z-index: 1000;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-height: 120px; /* Constrain the height of the dropdown */
  overflow-y: auto;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
  /*transform: translateY(25%);
  Adjust this to control the vertical position */
}

.suggestions-list li {
  padding: 8px 12px;
  cursor: pointer;
  text-align: left;
}

.suggestions-list li:hover {
  background: #eee;
}

.conditions {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
  margin: 10px 0px;
}

.condition-card {
  background-color: #F5F5F5;
  border-radius: 8px;
  padding: 10px;
  text-align: left;
}

.condition {
  font-family: Montserrat!important;
  font-size: 18px; 
}

.red-flag,
.common {
  width: 50%;
  text-transform: uppercase;
}

.common {
  text-align: right;
  text-transform: uppercase;
}

.ok-class {
  color: #27AE60;
  font-weight: bold;
  font-family: Montserrat!important;
}

.alert-class {
  color: #EB5757;
  font-weight: bold;
  font-family: Montserrat!important;
}

.diagnosis-text {
  width: 90%;
}

.diagnosis-icon {
  width: 10%;
  margin-left: 20px; /* Space between text and icon */
}

.diagnosis-icon img {
  width: 40px; /* Icon size */
  height: 40px; /* Icon size */
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');