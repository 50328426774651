.share-diagnosis {
    position: relative;
    display: inline-block;
    text-align: left;
    /* Ensure text alignment to the left */
    width: 100%;
    z-index: 1;
}

.share-popup {
    position: absolute;
    top: 30px;
    left: 0;
    display: flex;
    flex-direction: column;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
}

.share-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 5px 10px;
    margin: 5px 0;
    font-size: 24px;
    color: #333;
    text-decoration: none;
}

.share-button svg {
    margin-right: 8px; /* Add space between the icon and the text */
  }

.share-button:hover {
    background: #f0f0f0;
}

.whatsapp {
    color: #25D366;
}

.telegram {
    color: #0088cc;
}

.email {
    color: #D44638;
}